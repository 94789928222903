import React from 'react';
import flavrsClient from '../services/graphql/apolloClient';
import {ApolloProvider} from '@apollo/client';
import Router from './Router';

function App(): JSX.Element {
  return (
    <React.StrictMode>
      <ApolloProvider client={flavrsClient}>
        <Router />
      </ApolloProvider>
    </React.StrictMode>
  );
}

export default App;
