enum ButtonStyle {
  Small,
  Regular,
  Large,
  SmallOutlined,
  RegularOutlined,
  LargeOutlined,
}

export default ButtonStyle;
