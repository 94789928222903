import React from 'react';
import Markdown from 'markdown-to-jsx';
import {
  ChatIntent,
  ChatMessageMetadataFragment,
  ChatMessageRole,
  RecipeMetadataFragment,
} from '../../services/graphql/apolloTypes';

interface MessageItemProps {
  message: ChatMessageMetadataFragment;
  onRecipeClick: (recipe: RecipeMetadataFragment) => void;
}

const MessageItem: React.FC<MessageItemProps> = ({message, onRecipeClick}) => {
  const renderUserMessageContent = () => {
    if (message.media && message.media.length > 0) {
      const firstMedia = message.media[0];
      return (
        <div className="bg-taro text-charcoal p-3 rounded-lg max-w-[70%]">
          <img
            src={firstMedia.imageUrl}
            alt="User Image"
            className="rounded-lg w-[80px] h-[115px]"
          />
        </div>
      );
    } else if (message.ingredients && message.ingredients.length > 0) {
      return (
        <div className="bg-taro text-charcoal p-3 rounded-lg max-w-[70%]">
          {'What can I make with these:\n' + message.ingredients.join(', ')}
        </div>
      );
    } else {
      return (
        <div className="bg-taro text-charcoal p-3 rounded-lg max-w-[70%]">
          <Markdown>{message.content ? message.content + '\n' : ''}</Markdown>
        </div>
      );
    }
  };

  const renderAssistantMessageContent = () => {
    if (
      (message.intent === ChatIntent.RecipeRecommendation ||
        message.intent === ChatIntent.SearchByIngredients) &&
      message.recipes &&
      message.recipes.length > 0
    ) {
      const recipes = message.recipes;
      return (
        <div className="bg-transparent max-w-[90%] text-truffle">
          <div>
            {message.intent === ChatIntent.RecipeRecommendation
              ? 'We found some great options!'
              : 'We found some great recipes based on ingredients above!'}
          </div>
          <div className="flex flex-wrap mt-2">
            <div className="flex flex-wrap justify-start mt-2">
              {recipes.map(recipe => (
                <button
                  key={recipe.id}
                  onClick={() => {
                    onRecipeClick(recipe);
                  }}
                >
                  <div className="inline-flex items-center border border-ash text-persimmon text-sm rounded-full py-1 px-3 my-2 mr-2 h-8 overflow-hidden truncate max-w-xs md:max-w-2xl">
                    {recipe.title}
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="bg-transparent text-truffle max-w-[90%]">
          <Markdown>{message.content ? message.content + '\n' : ''}</Markdown>
        </div>
      );
    }
  };

  const renderMessageContent = () => {
    switch (message.role) {
      case ChatMessageRole.User:
        return renderUserMessageContent();
      case ChatMessageRole.Assistant:
        return renderAssistantMessageContent();
    }
  };

  return (
    <div
      key={message.id}
      className={`flex ${
        message.role === ChatMessageRole.User ? 'justify-end' : 'justify-start'
      }`}
    >
      {renderMessageContent()}
    </div>
  );
};

export default MessageItem;
