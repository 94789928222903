import React from 'react';
import ButtonStyle from './ButtonStyle';
import ButtonStyleUtils from './ButtonStyleUtils';

type SecondaryButtonProps = {
  style: ButtonStyle;
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
};

const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  style,
  onClick,
  children,
  className = '',
  icon,
}) => {
  const borderStyle = ButtonStyleUtils.getBorderStyle(style, true);
  const horizontalPadding = ButtonStyleUtils.getHorizontalPadding(style);
  const verticalPadding = ButtonStyleUtils.getVerticalPadding(style);
  const textStyle = ButtonStyleUtils.getTextSize(style);
  const iconSize = ButtonStyleUtils.getIconSize(style);

  return (
    <button
      onClick={onClick}
      className={`bg-sugar text-charcoal font-inter font-semibold rounded-full ${borderStyle} ${horizontalPadding} ${textStyle} ${verticalPadding} ${className}`}
    >
      <div className="flex items-center justify-center gap-2">
        {icon &&
          React.cloneElement(icon as React.ReactElement, {
            style: iconSize,
          })}
        {children}
      </div>
    </button>
  );
};

export default SecondaryButton;
