import React from 'react';

type AvatarImageProps = {
  url: string | null | undefined;
  placeholder: string;
  placeholderTextClass: string;
  className?: string;
};

const placeholderColors = [
  '#FFCA98',
  '#FF9571',
  '#B2DDE5',
  '#E0E090',
  '#EABBCD',
  '#E7AAE8',
  '#A7AFE1',
];

const getPlaceholderColor = (input: string): string => {
  const firstCharCode = input.charCodeAt(0);
  const lastCharCode = input.charCodeAt(1);

  const hashValue = (firstCharCode + lastCharCode) % placeholderColors.length;

  return placeholderColors[hashValue];
};

const AvatarImage: React.FC<AvatarImageProps> = ({
  url,
  placeholder,
  placeholderTextClass,
  className = '',
}) => {
  const backgroundColor = getPlaceholderColor(
    placeholder.length >= 2 ? placeholder.substring(0, 2) : '  '
  );

  return url ? (
    <img src={url} alt="Avatar image" className={`rounded-full ${className}`} />
  ) : (
    <div
      className={`rounded-full ${placeholderTextClass} ${className} text-sugar flex items-center justify-center`}
      style={{backgroundColor}}
    >
      {placeholder.length >= 2 ? placeholder.substring(0, 2) : '  '}
    </div>
  );
};

export default AvatarImage;
