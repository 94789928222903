import {ApolloClient, InMemoryCache} from '@apollo/client';
import {createUploadLink} from 'apollo-upload-client';

import {APOLLO_ENABLE_DEVTOOLS, APOLLO_SERVER_URL} from '../../app/config';

import {StrictTypedTypePolicies} from './apolloHelpers';
import {ChatMessageList} from './apolloTypes';

const typePolicies: StrictTypedTypePolicies = {
  Query: {
    fields: {
      recipes: {
        keyArgs: ['searchConditions', ['limit']],
        merge(
          existing: ChatMessageList,
          incoming: ChatMessageList
        ): ChatMessageList {
          return {
            totalCount: incoming.totalCount,
            hasMore: incoming.hasMore,
            cursor: incoming.cursor,
            messages: [
              ...(existing ? existing.messages : []),
              ...incoming.messages,
            ],
          };
        },
      },
    },
  },
};

const cache = new InMemoryCache({
  typePolicies,
});

const httpLink = createUploadLink({
  uri: APOLLO_SERVER_URL,
  credentials: 'include',
});

const client = new ApolloClient({
  link: httpLink,
  cache: cache,
  connectToDevTools: APOLLO_ENABLE_DEVTOOLS,
  name: 'web-ai',
});

export default client;
