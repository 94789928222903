import {useState, useEffect} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {v4 as uuidv4} from 'uuid';
import {
  ChatMessagesDocument,
  AskChatMessageDocument,
  ChatMessageRole,
  ChatMessageMetadataFragment,
} from '../../services/graphql/apolloTypes';
import Avo from '../../analytics/Avo';
import AnalyticsViewName from '../../analytics/AnalyticsViewName';

const LIMIT = 20;

export function useAssistantMessages() {
  const [askChatMessage] = useMutation(AskChatMessageDocument);
  const {loading, error, data, refetch} = useQuery(ChatMessagesDocument, {
    variables: {
      searchConditions: {
        limit: LIMIT,
      },
    },
    onError: error => {
      console.error('GraphQL Error:', error);
    },
  });

  const [isProcessingNewMessage, setIsProcessingNewMessage] = useState(false);
  const [messages, setMessages] = useState<ChatMessageMetadataFragment[]>([]);

  useEffect(() => {
    if (data && data.chatMessages && data.chatMessages.messages) {
      setMessages([...data.chatMessages.messages].reverse());
    }
  }, [data]);

  const handleFetch = async () => {
    await refetch({
      searchConditions: {
        limit: LIMIT,
      },
    });
  };

  const handleSendMessage = async (text: string) => {
    if (isProcessingNewMessage) {
      return;
    }
    setIsProcessingNewMessage(true);
    const userMessage = {
      id: uuidv4(),
      createdAt: new Date().toISOString(),
      role: ChatMessageRole.User,
      content: text,
    };

    setMessages(prevMessages => [...prevMessages, userMessage]);

    try {
      const response = await askChatMessage({
        variables: {
          message: text,
        },
        refetchQueries: [
          {
            query: ChatMessagesDocument,
            variables: {
              searchConditions: {limit: LIMIT},
            },
          },
        ],
      });

      const assistantResponse = response.data?.askWebChatMessage;
      if (assistantResponse) {
        Avo.webAssistantReplied({
          viewName: AnalyticsViewName.AssistantPage,
          viewEntityId: null,
          viewEntityType: null,
          messageId: assistantResponse.id,
        });
        const assistantMessage = {
          id: assistantResponse.id,
          createdAt: assistantResponse.createdAt,
          intent: assistantResponse.intent,
          role: ChatMessageRole.Assistant,
          content: assistantResponse.content,
          recipes: assistantResponse.recipes,
          ingredients: assistantResponse.ingredients,
        };

        setMessages(prevMessages => [...prevMessages, assistantMessage]);
      }
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsProcessingNewMessage(false);
    }
  };

  return {
    messages,
    handleFetch,
    handleSendMessage,
    loading,
    error,
    data,
    isProcessingNewMessage,
  };
}
