import React from 'react';

type FlavrsAIWordmarkProps = {
  width: number;
  height: number;
  className?: string;
};

function FlavrsAIWordmark({
  width,
  height,
  className = '',
}: FlavrsAIWordmarkProps): JSX.Element {
  return (
    <img
      src="/flavrs-ai-wordmark.svg"
      alt="Flavrs AI Logo"
      className={`${className}`}
      style={{width: width, height: height}}
    />
  );
}

export default FlavrsAIWordmark;
