import React from 'react';
import {useNavigate} from 'react-router-dom';
import {
  SecondaryButton,
  FlavrsAILogo,
  WhiteFlavrsWordmark,
  ButtonStyle,
} from '../../components';

function Future404(): JSX.Element {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="bg-cover bg-center bg-mobile md:bg-desktop bg-zoomed-mobile md:bg-zoomed-desktop bg-top md:bg-right-bottom w-full h-screen">
        <div className="mx-auto max-w-2xl text-center px-12 pt-8">
          <FlavrsAILogo
            width={64}
            height={64}
            className="mx-auto mb-4"
          ></FlavrsAILogo>
          <WhiteFlavrsWordmark
            width={92}
            height={22}
            className="mx-auto mb-20"
          ></WhiteFlavrsWordmark>
          <h1 className="text-white text-[58px] font-simula font-normal leading-[64px] mb-4">
            {'Oops!'}
          </h1>
          <h1 className="text-white text-[48px] font-simula font-normal leading-[64px] mb-20">
            {'Page Not Found'}
          </h1>
          <p className="text-white text-[18px] font-inter font-medium leading-[23.4px] mb-32">
            The page you are looking for was moved, removed, renamed or might
            never existed.
          </p>
          <SecondaryButton
            style={ButtonStyle.Large}
            onClick={() => navigate('/')}
            className="mb-20"
          >
            Go Home
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
}

export default Future404;
