import React, {useEffect, useRef, useState} from 'react';
import {AvatarImage, ButtonStyle, SecondaryButton} from '../../components';
import {IoIosArrowBack} from 'react-icons/io';
import {RWebShare} from 'react-web-share';
import {IoShareOutline} from 'react-icons/io5';
import {useNavigate, useParams} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import {
  RecipeMetadataFragment,
  RecipeMetadataDocument,
} from '../../services/graphql/apolloTypes';
import MuxPlayer from '@mux/mux-player-react';
// eslint-disable-next-line n/no-extraneous-import
import type MuxPlayerElement from '@mux/mux-player';
import {useQuery} from '@apollo/client';
import Avo from '../../analytics/Avo';
import AnalyticsViewName from '../../analytics/AnalyticsViewName';
import AnalyticsEntityType from '../../analytics/AnalyticsEntityType';
import RecipeDetailsCard from './RecipeDetailsCard';
import {BiFoodMenu} from 'react-icons/bi';

function Recipe(): JSX.Element {
  const {recipeId} = useParams<{recipeId: string}>();
  const [recipe, setRecipe] = useState<RecipeMetadataFragment | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const initialized = useRef(false);
  const [isRecipeDetailsOpen, setIsRecipeDetailsOpen] = useState(false);
  const videoRef = useRef<MuxPlayerElement | null>(null);

  const {refetch} = useQuery(RecipeMetadataDocument, {
    skip: !recipeId,
    variables: {recipeId: recipeId || ''},
    onError: error => {
      console.error('GraphQL Error:', error);
    },
  });

  const fetchRecipe = async (recipeId: string) => {
    try {
      const response = await refetch({
        recipeId: recipeId,
      });
      if (response.data?.webRecipe) {
        setRecipe(response.data.webRecipe as RecipeMetadataFragment);
      }
    } catch (error) {
      console.error('Failed to fetch recipe:', error);
    }
  };

  useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    const recipe = location.state?.recipe;
    let analyticsRecipeId = '';
    if (recipe as RecipeMetadataFragment) {
      analyticsRecipeId = recipe.id;
      setRecipe(recipe);
    } else if (recipeId) {
      analyticsRecipeId = recipeId;
      fetchRecipe(recipeId);
    }
    Avo.webView({
      viewName: AnalyticsViewName.RecipePage,
      viewEntityId: analyticsRecipeId,
      viewEntityType: AnalyticsEntityType.Recipe,
    });
  }, [location.state]);

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const handleShare = () => {
    if (recipe) {
      Avo.webRecipeShared({
        viewName: AnalyticsViewName.RecipePage,
        viewEntityId: null,
        viewEntityType: null,
        recipeId: recipe.id,
      });
    } else {
      Avo.webAssistantShared({
        viewName: AnalyticsViewName.RecipePage,
        viewEntityId: null,
        viewEntityType: null,
      });
    }
  };

  const toggleRecipeDetailsCard = () => {
    if (isRecipeDetailsOpen) {
      setIsRecipeDetailsOpen(false);
      if (videoRef.current) {
        videoRef.current.play().catch(error => {
          console.error(error);
        });
      }
    } else {
      setIsRecipeDetailsOpen(true);
      if (videoRef.current) {
        videoRef.current.pause();
      }
    }
  };

  return (
    <div className="w-screen h-screen flex items-center justify-center bg-black ">
      {recipe && (
        <div className="w-full md:w-[40%] h-screen bg-black relative">
          <div className="absolute top-0 w-full flex items-center p-2 mb-2 z-40">
            <SecondaryButton
              style={ButtonStyle.Small}
              onClick={handleBackButtonClick}
              icon={<IoIosArrowBack />}
            >
              Back
            </SecondaryButton>
            <RWebShare
              data={{
                url: recipe
                  ? 'https://ai.flavrs.com/recipe/' + recipe.id
                  : 'https://ai.flavrs.com',
                title: recipe
                  ? 'Check out "' +
                    recipe.title +
                    '" on the flavrsAI cooking assistant!'
                  : 'Check this recipe on the flavrsAI cooking assistant!',
              }}
              onClick={handleShare}
            >
              <SecondaryButton
                style={ButtonStyle.Small}
                onClick={function (): void {}}
                className="ml-auto"
                icon={<IoShareOutline />}
              >
                Share
              </SecondaryButton>
            </RWebShare>
          </div>
          <div
            className="absolute inset-0"
            style={{
              background:
                'linear-gradient(180deg, rgba(89, 86, 84, 0.00) 50%, rgba(28, 21, 18, 0.80) 100%)',
            }}
          ></div>
          <MuxPlayer
            playbackId={recipe.videoPlaybackId ? recipe.videoPlaybackId : ''}
            autoPlay
            loop
            className="absolute inset-0 w-full h-full object-cover"
            ref={videoRef}
          />
          <div className="absolute inset-x-0 bottom-0 p-4 style={{ width: 'calc(100% * 9 / 16)' }}">
            <div className="flex items-center">
              <AvatarImage
                url={recipe.author.avatarUrl}
                placeholder={recipe.author.username}
                placeholderTextClass="text-sm"
                className="w-8 h-8"
              />
              <div className="ml-2 text-[16px] text-sugar">
                {recipe.author.username}
              </div>
            </div>
            <div className="text-[22px] text-white font-semibold line-clamp-2 mt-2">
              {recipe.title}
            </div>
            <div className="w-full flex flex-wrap gap-2 mt-2">
              {recipe.tags.map(tag => (
                <div
                  key={tag.id}
                  className="flex items-center justify-center gap-1 rounded-md py-1 px-1 text-white text-[12px] bg-black bg-opacity-40"
                >
                  {tag.displayName}
                </div>
              ))}
            </div>
            <SecondaryButton
              style={ButtonStyle.Small}
              icon={<BiFoodMenu />}
              onClick={toggleRecipeDetailsCard}
              className="w-full mt-4"
            >
              View recipe
            </SecondaryButton>
          </div>
        </div>
      )}
      {isRecipeDetailsOpen && recipe && (
        <RecipeDetailsCard recipe={recipe} onClose={toggleRecipeDetailsCard} />
      )}
    </div>
  );
}

export default Recipe;
