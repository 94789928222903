import React from 'react';
import ButtonStyle from './ButtonStyle';
import ButtonStyleUtils from './ButtonStyleUtils';

type PrimaryButtonProps = {
  style: ButtonStyle;
  onClick: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
  icon?: React.ReactNode;
};

function PrimaryButton({
  style,
  onClick,
  children,
  disabled = false,
  className = '',
  icon,
}: PrimaryButtonProps): JSX.Element {
  const borderStyle = ButtonStyleUtils.getBorderStyle(style, true);
  const horizontalPadding = ButtonStyleUtils.getHorizontalPadding(style);
  const verticalPadding = ButtonStyleUtils.getVerticalPadding(style);
  const textStyle = ButtonStyleUtils.getTextSize(style);
  const iconSize = ButtonStyleUtils.getIconSize(style);

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`bg-charcoal text-sugar font-inter font-semibold rounded-full ${borderStyle} ${horizontalPadding} ${textStyle} ${verticalPadding} ${className}`}
    >
      <div className="flex items-center justify-center gap-2">
        {icon &&
          React.cloneElement(icon as React.ReactElement, {
            style: iconSize,
          })}
        {children}
      </div>
    </button>
  );
}

export default PrimaryButton;
