import React from 'react';

type FlavrsAILogoProps = {
  width: number;
  height: number;
  className?: string;
};

function FlavrsAILogo({
  width,
  height,
  className = '',
}: FlavrsAILogoProps): JSX.Element {
  return (
    <img
      src="/flavrs-ai.svg"
      alt="Flavrs AI Logo"
      className={`${className}`}
      style={{width: width, height: height}}
    />
  );
}

export default FlavrsAILogo;
