import React from 'react';
import {FaPlay} from 'react-icons/fa';
import {RecipeMetadataFragment} from '../../services/graphql/apolloTypes';
import {AvatarImage} from '../../components';

type RecipeCardProps = {
  recipe: RecipeMetadataFragment;
  className?: string;
};

function RecipeCard({recipe, className = ''}: RecipeCardProps): JSX.Element {
  return (
    <div
      className={`relative rounded-lg shadow-lg overflow-hidden ${className}`}
    >
      <img
        src={recipe.imageUrl ? recipe.imageUrl : ''}
        alt="Recipe image"
        className="absolute inset-0 w-full h-full object-cover"
      />
      <div
        className="absolute inset-0"
        style={{
          background:
            'linear-gradient(180deg, rgba(89, 86, 84, 0.00) 50%, rgba(28, 21, 18, 0.80) 100%)',
        }}
      ></div>
      <div className="absolute inset-0 flex items-center justify-center">
        <FaPlay className="w-10 h-10 text-opacity-75 text-sugar" />
      </div>
      <div className="absolute inset-x-0 bottom-0 p-4 text-start justify-start">
        <div className="text-regular text-white line-clamp-2">
          {recipe.title}
        </div>
        <div className="flex items-center mt-2">
          <AvatarImage
            url={recipe.author.avatarUrl}
            placeholder={recipe.author.username}
            placeholderTextClass="text-xs"
            className="w-6 h-6"
          />
          <div className="ml-2 text-sm text-sugar">
            {recipe.author.username}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecipeCard;
