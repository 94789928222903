import React, {useState} from 'react';
import {MdOutlineCameraAlt} from 'react-icons/md';
import {FaCircleArrowUp} from 'react-icons/fa6';

interface AssistantTextFieldProps {
  onCameraClick: () => void;
  onSendClick: (text: string) => Promise<void>;
  disableSend: boolean;
}

const AssistantTextField: React.FC<AssistantTextFieldProps> = ({
  onCameraClick,
  onSendClick,
  disableSend,
}) => {
  const [text, setText] = useState('');

  return (
    <div className="flex items-center w-full">
      <div className="flex items-center mr-2">
        <button className="text-charcoal" onClick={onCameraClick}>
          <MdOutlineCameraAlt size={24} />
        </button>
      </div>
      <div className="flex items-center border border-gray-300 rounded-full px-4 py-2 w-full bg-white">
        <input
          type="text"
          className="flex-grow mx-2 outline-none text-gray-900"
          placeholder="Ask a food question"
          value={text}
          onChange={e => {
            setText(e.target.value);
          }}
          onKeyDown={e => {
            if (e.key === 'Enter' && !disableSend) {
              onSendClick(text);
              setText('');
            }
          }}
        />
        {text && (
          <button
            className="text-orange-500"
            disabled={disableSend}
            onClick={() => {
              onSendClick(text);
              setText('');
            }}
          >
            <FaCircleArrowUp size={24} />
          </button>
        )}
      </div>
    </div>
  );
};

export default AssistantTextField;
