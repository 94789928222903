/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as RudderStack from 'rudder-sdk-js';
import {
  REACT_APP_RUDDERSTACK_WRITE_KEY,
  REACT_APP_RUDDERSTACK_DATAPLANE_URL,
} from '../app/config';
import {AvoEnv, CustomDestination} from './Avo';

class AvoRudderStackDestination implements CustomDestination {
  make(env: AvoEnv, apiKey: string): void {
    RudderStack.load(
      REACT_APP_RUDDERSTACK_WRITE_KEY,
      REACT_APP_RUDDERSTACK_DATAPLANE_URL
    );
  }

  identify(userId: string): void {
    RudderStack.identify(userId);
  }

  setUserProperties(userId: string, userProperties: Record<string, any>): void {
    RudderStack.identify(userId, userProperties);
  }

  unidentify(): void {
    RudderStack.reset();
  }

  logEvent(eventName: string, eventProperties: Record<string, any>): void {
    RudderStack.track(eventName, eventProperties);
  }

  logPage(pageName: string, eventProperties: Record<string, any>): void {
    RudderStack.page(pageName, eventProperties);
  }
}

export default AvoRudderStackDestination;
