import React, {useEffect, useState, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import Lottie from 'lottie-react';
import sauteingPanAnimation from './SauteingPan.json';
import scanningLineAnimation from './ScanningLine.json';
import {FlavrsAIWordmark} from '../../components';
import {useUploadImage} from './useUploadImage';
import {useAssistant} from './useAssistant';
import {useNavigate} from 'react-router-dom';
import {SEARCH_RESULTS_ROUTE} from '../../app/routes';
import {IoReload} from 'react-icons/io5';
import AnalyticsViewName from '../../analytics/AnalyticsViewName';
import Avo from '../../analytics/Avo';

function ImageUpload(): JSX.Element {
  const {askAssistantWithPhoto} = useAssistant();
  const location = useLocation();
  const [currentTextIndex, setCurrentTextIndex] = useState<number>(0);
  const [didImageProcessFail, setDidImageProcessFail] =
    useState<boolean>(false);
  const [isProcessingImage, setIsProcessingImage] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string>('');
  const navigate = useNavigate();
  const uploadImage = useUploadImage();
  const initialized = useRef(false);

  const supportingTexts = [
    'Hold tight, we’re matching your ingredients to our recipes',
    'Sit tight, we’re cooking something special',
    'Something yummy is cooking',
    'Almost there, wait just a little longer...',
    'Crafting a gourmet experience just for you',
    'Ensuring everything is just right',
  ];

  const handleImageUpload = async () => {
    const file = location.state?.selectedFile;
    if (file) {
      try {
        setIsProcessingImage(true);
        setDidImageProcessFail(false);
        const gcpPath = await uploadImage(file);
        const photoResponse = await askAssistantWithPhoto(
          gcpPath,
          file,
          AnalyticsViewName.ImageUploadPage
        );
        setIsProcessingImage(false);
        navigate(SEARCH_RESULTS_ROUTE, {
          state: {
            recipes: photoResponse.recipes,
            ingredients: photoResponse.ingredients,
            selectedFile: file,
          },
        });
      } catch (error) {
        console.error('Error during image upload or processing:', error);
        setIsProcessingImage(false);
        setDidImageProcessFail(true);
      }
    }
  };

  useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    const file = location.state?.selectedFile;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result as string);
      };
      reader.readAsDataURL(file);
      handleImageUpload();
    }
    Avo.webView({
      viewName: AnalyticsViewName.ImageUploadPage,
      viewEntityId: null,
      viewEntityType: null,
    });
  }, [location.state]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex(
        prevIndex => (prevIndex + 1) % supportingTexts.length
      );
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-screen h-screen bg-salt flex flex-col md:flex-row md:justify-between items-center px-8 pt-16 pb-24">
      <div className="relative w-full md:w-1/2 flex flex-col items-center mb-6 md:mb-12">
        <div className="relative w-56 md:w-[340px] h-80 md:h-[480px] mt-0 mb-0">
          <img
            className="w-full h-full rounded-lg object-cover"
            src={imageSrc}
            alt="Selected Image"
          />
          <div className="absolute inset-0">
            {isProcessingImage && (
              <Lottie
                animationData={scanningLineAnimation}
                loop={true}
                className="w-full h-full rounded-lg"
              />
            )}
          </div>
        </div>
      </div>
      {isProcessingImage && (
        <div className="relative w-full md:w-1/2 flex flex-col items-center mb-6 md:mb-12">
          <Lottie
            animationData={sauteingPanAnimation}
            loop={true}
            className="w-40 h-48 md:w-80 md:h-96"
          />
          <div className="w-full text-center text-xl md:text-2xl font-semibold text-truffle mt-2 font-inter">
            {supportingTexts[currentTextIndex]}
          </div>
        </div>
      )}
      {didImageProcessFail && (
        <div className="relative w-full md:w-1/2 flex flex-col items-center mb-6 md:mb-12">
          <button
            onClick={handleImageUpload}
            className="mt-4 bg-ash bg-opacity-10 rounded-xl"
          >
            <div className="p-3 flex flex-col items-center">
              <IoReload className="w-12 h-12 p-3 bg-orange-200 text-persimmon rounded-full" />
              <div className="text-regular font-semibold text-charcoal mt-2">
                Tap to retry
              </div>
            </div>
          </button>
        </div>
      )}
      <div className="fixed top-0 w-full bg-salt p-2 mb-2 z-50 pl-0">
        <FlavrsAIWordmark width={120} height={24} />
      </div>
    </div>
  );
}

export default ImageUpload;
