import {useMutation} from '@apollo/client';
import {CreateMediaUploadUrLsDocument} from '../../services/graphql/apolloTypes';
import axios, {AxiosRequestConfig} from 'axios';

export const useUploadImage = () => {
  const [createMediaUploadUrl] = useMutation(CreateMediaUploadUrLsDocument);

  const uploadImage = async (file: File): Promise<string> => {
    try {
      const response = await createMediaUploadUrl({
        variables: {inputs: [{contentType: file.type}]},
      });

      if (
        response.data?.createWebMediaUploadURLs &&
        response.data.createWebMediaUploadURLs.length > 0
      ) {
        const data = response.data.createWebMediaUploadURLs[0];
        const {uploadUrl, gcpPath} = data;

        const config: AxiosRequestConfig<File> = {
          headers: {
            'Content-Type': file.type,
          },
        };

        const uploadResponse = await axios.put(uploadUrl, file, config);

        if (uploadResponse.status === 200) {
          return gcpPath;
        } else {
          throw new Error(
            `Failed to upload file: ${uploadResponse.statusText}`
          );
        }
      } else {
        throw new Error('Failed to get upload URL');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      throw new Error('Error uploading image');
    }
  };

  return uploadImage;
};
