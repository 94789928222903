// import {FaArrowRotateLeft} from 'react-icons/fa6';
// import {GoPlus} from 'react-icons/go';
import React, {useEffect, useRef, useState} from 'react';
import {
  SecondaryButton,
  ButtonStyle,
  FlavrsFooter,
  PrimaryButton,
} from '../../components';
import {IoShareOutline, IoSearch, IoReload} from 'react-icons/io5';
import {IoIosArrowBack, IoMdClose} from 'react-icons/io';
import {RWebShare} from 'react-web-share';
import RecipeCard from './RecipeCard';
import {useLocation, useNavigate} from 'react-router-dom';
import {ASSISTANT_ROUTE, RECIPE_ROUTE} from '../../app/routes';
import {RecipeMetadataFragment} from '../../services/graphql/apolloTypes';
import {useAssistant} from '../imageUpload/useAssistant';
import sauteingPanAnimation from '../imageUpload/SauteingPan.json';
import Lottie from 'lottie-react';
import Avo from '../../analytics/Avo';
import AnalyticsViewName from '../../analytics/AnalyticsViewName';

function SearchResults(): JSX.Element {
  const {askAssistantWithIngredients, ingredientsError} = useAssistant();
  const location = useLocation();
  const [currentTextIndex, setCurrentTextIndex] = useState<number>(0);
  const [imageSrc, setImageSrc] = useState<string>('');
  const [ingredients, setIngredients] = useState<string[]>([]);
  const [recipes, setRecipes] = useState<RecipeMetadataFragment[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newIngredient, setNewIngredient] = useState('');
  const [hasEditedIngredients, setHasEditedIngredients] = useState(false);
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);
  const navigate = useNavigate();
  const initialized = useRef(false);

  const supportingTexts = [
    'Hold tight, we’re matching your ingredients to our recipes',
    'Sit tight, we’re cooking something special',
    'Something yummy is cooking',
    'Almost there, wait just a little longer...',
    'Crafting a gourmet experience just for you',
    'Ensuring everything is just right',
  ];

  useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    const file = location.state?.selectedFile;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result as string);
      };
      reader.readAsDataURL(file);
    }

    const savedIngredients = localStorage.getItem('searchIngredients');
    const savedRecipes = localStorage.getItem('searchRecipes');
    if (savedIngredients && savedRecipes) {
      setIngredients(JSON.parse(savedIngredients));
      setRecipes(JSON.parse(savedRecipes));
    } else {
      const ingredients = location.state?.ingredients;
      if (ingredients as string[]) {
        setIngredients(ingredients);
      }

      const recipes = location.state?.recipes;
      if (recipes as RecipeMetadataFragment[]) {
        setRecipes(recipes);
      }
    }
    Avo.webView({
      viewName: AnalyticsViewName.SearchResultsPage,
      viewEntityId: null,
      viewEntityType: null,
    });
  }, [location.state]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex(
        prevIndex => (prevIndex + 1) % supportingTexts.length
      );
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleBackButtonClick = () => {
    navigate(ASSISTANT_ROUTE);
  };

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => {
    setIsModalOpen(false);
    setNewIngredient('');
  };

  const handleAddIngredient = () => {
    if (newIngredient.length > 0) {
      setIngredients(prevIngredients => [...prevIngredients, newIngredient]);
      setHasEditedIngredients(true);
      closeModal();
    }
  };

  const handleRemoveIngredient = (itemToDelete: string) => {
    setHasEditedIngredients(true);
    setIngredients(
      ingredients.filter(ingredient => {
        return ingredient !== itemToDelete;
      })
    );
  };

  const handleSearchByIngredientsButtonClick = async () => {
    try {
      setHasEditedIngredients(false);
      setIsLoadingResponse(true);
      const response = await askAssistantWithIngredients(
        ingredients,
        AnalyticsViewName.SearchResultsPage
      );
      setIsLoadingResponse(false);
      if (response.recipes) {
        setRecipes(response.recipes);
      }
    } catch (error) {
      setIsLoadingResponse(false);
      console.error(error);
    }
  };

  const handleRecipeClick = (recipe: RecipeMetadataFragment) => {
    Avo.webRecipeOpened({
      viewName: AnalyticsViewName.SearchResultsPage,
      viewEntityId: null,
      viewEntityType: null,
      recipeId: recipe.id,
    });
    navigate(`${RECIPE_ROUTE}/${recipe.id}`, {state: {recipe}});
  };

  const handleShare = () => {
    Avo.webAssistantShared({
      viewName: AnalyticsViewName.SearchResultsPage,
      viewEntityId: null,
      viewEntityType: null,
    });
  };

  return (
    <div className="w-screen h-screen bg-sugar flex flex-col items-center px-4 pt-14 pb-24">
      <div className="fixed top-0 w-full flex items-center justify-start bg-sugar p-2 mb-2 z-40">
        <SecondaryButton
          style={ButtonStyle.Small}
          onClick={handleBackButtonClick}
          icon={<IoIosArrowBack />}
        >
          Back to chat
        </SecondaryButton>
        <RWebShare
          data={{
            url: 'https://ai.flavrs.com',
            title: 'Try the 1st AI Cooking Assistant',
          }}
          onClick={handleShare}
        >
          <SecondaryButton
            style={ButtonStyle.Small}
            onClick={() => {}}
            className="ml-auto"
            icon={<IoShareOutline />}
          >
            Share
          </SecondaryButton>
        </RWebShare>
      </div>
      {imageSrc !== '' && (
        <div className="w-full md:w-[95%] flex flex-row items-center rounded-full bg-salt mb-6">
          <IoSearch className="text-shell w-6 h-6 m-4" />
          <img
            className="w-12 h-12 rounded-lg my-2"
            src={imageSrc}
            alt="Selected Image"
          />
          {/* <button className="w-12 h-12 rounded-lg my-2 ml-2 border border-ash">
          <GoPlus className="w-6 h-6 text-ash ml-[11px]" />
        </button> */}
          {/* <button className="flex flex-row items-center m-4 ml-auto">
          <FaArrowRotateLeft className="text-persimmon w-4 h-4" />
          <div className="w-full text-base font-semibold text-persimmon font-inter ml-1">
            Retake
          </div>
        </button> */}
        </div>
      )}
      <div className="w-full md:w-[95%] flex flex-col items-center rounded-2xl border-2 border-taro p-4 mb-6">
        <div className="w-full text-base font-semibold text-charcoal font-inter">
          Ingredients you want to use
        </div>
        <div className="w-full text-xs font-medium text-shell font-inter">
          Deselect the ones you don’t want to include
        </div>
        <div className="w-full flex flex-wrap gap-2 my-3">
          {ingredients.map(ingredient => (
            <div
              key={ingredient}
              className="flex items-center justify-center gap-2 border border-ash rounded-full py-1 px-3"
            >
              {ingredient}
              <button
                onClick={() => {
                  handleRemoveIngredient(ingredient);
                }}
              >
                <IoMdClose />
              </button>
            </div>
          ))}
        </div>
        <div className="w-full text-sm font-medium text-yolk font-inter">
          <button onClick={openModal} disabled={isLoadingResponse}>
            + Add another
          </button>
        </div>
        {hasEditedIngredients && ingredients.length > 0 && (
          <div className="w-full text-center md:text-start mt-4">
            <PrimaryButton
              style={ButtonStyle.Regular}
              onClick={handleSearchByIngredientsButtonClick}
            >
              Refresh recipe results
            </PrimaryButton>
          </div>
        )}
      </div>

      {isLoadingResponse ? (
        <div className="relative w-full md:w-1/2 flex flex-col items-center mb-6 md:mb-12">
          <Lottie
            animationData={sauteingPanAnimation}
            loop={true}
            className="w-40 h-48 md:w-80 md:h-96"
          />
          <div className="w-full text-center text-xl md:text-2xl font-semibold text-truffle mt-2 font-inter">
            {supportingTexts[currentTextIndex]}
          </div>
        </div>
      ) : ingredientsError ? (
        <div className="relative w-full md:w-1/2 flex flex-col items-center mb-6 md:mb-12">
          <button
            onClick={handleSearchByIngredientsButtonClick}
            className="mt-4 bg-ash bg-opacity-10 rounded-xl"
          >
            <div className="p-3 flex flex-col items-center">
              <IoReload className="w-12 h-12 p-3 bg-orange-200 text-persimmon rounded-full" />
              <div className="text-regular font-semibold text-charcoal mt-2">
                Tap to retry
              </div>
            </div>
          </button>
        </div>
      ) : (
        <div className="w-full">
          <div className="w-full md:w-[95%] text-base font-semibold text-charcoal font-inter">
            {recipes.length} recipes based on ingredients above
          </div>
          <div className="w-full md:w-[95%] flex flex-wrap gap-4 my-3">
            {recipes.map(recipe => (
              <button
                key={recipe.id}
                className="w-[calc(50%-0.5rem)] md:w-[220px] h-[280px] md:h-[320px]"
                onClick={() => {
                  handleRecipeClick(recipe);
                }}
              >
                <RecipeCard recipe={recipe} className="w-full h-full" />
              </button>
            ))}
          </div>
        </div>
      )}

      <div className="w-screen bg-uni flex justify-center items-center py-3">
        <div className="flex space-x-[26px]">
          <div>
            <img
              src="./search-results-thumbnail.svg"
              alt=""
              className="w-[160px] h-[260px]"
            />
          </div>
          <div className="w-2/5 md:w-[200px] flex flex-col text-start mt-3">
            <div className="w-44 text-[22px] font-semibold text-charcoal font-inter text-start mb-3">
              Get the flavrs app for the full experience
            </div>
            <div className="w-44 text-sm font-normal text-truffle font-inter text-start mb-4">
              AI cooking assistant, thousands of video recipes with full
              details, and more!
            </div>
            <a
              href="https://apps.apple.com/us/app/flavrs-watch-shop-eat/id1584828914"
              className="w-40"
            >
              <PrimaryButton
                style={ButtonStyle.Small}
                onClick={function (): void {}}
                className="w-40"
              >
                Download iOS app
              </PrimaryButton>
            </a>
          </div>
        </div>
      </div>

      <FlavrsFooter className="w-screen"></FlavrsFooter>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-80">
            <h2 className="text-xl font-bold mb-4 text-center">
              Add ingredient
            </h2>
            <input
              type="text"
              value={newIngredient}
              onChange={e => setNewIngredient(e.target.value)}
              className="w-full p-2 mb-6 border rounded-md bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter ingredient"
            />
            <div className="flex flex-col gap-2">
              <PrimaryButton
                style={ButtonStyle.Regular}
                onClick={handleAddIngredient}
              >
                Done
              </PrimaryButton>
              <SecondaryButton
                style={ButtonStyle.RegularOutlined}
                onClick={closeModal}
              >
                Cancel
              </SecondaryButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchResults;
