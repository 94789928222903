import React, {useRef, useEffect} from 'react';
import {
  FlavrsAILogo,
  PrimaryButton,
  ButtonStyle,
  FlavrsAIWordmark,
  SecondaryButton,
} from '../../components';
import {MdOutlineCameraAlt} from 'react-icons/md';
import {IoReload, IoShareOutline} from 'react-icons/io5';
import AssistantTextField from './AssistantTextField';
import {useAssistantMessages} from './useAssistantMessages';
import loadingSpinnerAnimation from './LoadingSpinner.json';
import chatMessageLoadingAnimation from './ChatMessageLoading.json';
import Lottie from 'lottie-react';
import MessageItem from './MessageItem';
import {useNavigate} from 'react-router-dom';
import {IMAGE_UPLOAD_ROUTE, RECIPE_ROUTE} from '../../app/routes';
import {RecipeMetadataFragment} from '../../services/graphql/apolloTypes';
import {RWebShare} from 'react-web-share';
import Avo from '../../analytics/Avo';
import AnalyticsViewName from '../../analytics/AnalyticsViewName';

function Assistant(): JSX.Element {
  const {
    messages,
    handleFetch,
    handleSendMessage,
    loading,
    error,
    isProcessingNewMessage,
  } = useAssistantMessages();

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  const initialized = useRef(false);
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({behavior: 'smooth'});
    }
  };

  const fetchMessages = async () => {
    await handleFetch();
    scrollToBottom();
  };

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    fetchMessages();
    Avo.webView({
      viewName: AnalyticsViewName.AssistantPage,
      viewEntityId: null,
      viewEntityType: null,
    });
  }, [messages]);

  const handleCameraButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      navigate(IMAGE_UPLOAD_ROUTE, {state: {selectedFile}});
    }
  };
  const handleRecipeClick = (recipe: RecipeMetadataFragment) => {
    Avo.webRecipeOpened({
      viewName: AnalyticsViewName.AssistantPage,
      viewEntityId: null,
      viewEntityType: null,
      recipeId: recipe.id,
    });
    navigate(`${RECIPE_ROUTE}/${recipe.id}`, {state: {recipe}});
  };

  const handleShare = () => {
    Avo.webAssistantShared({
      viewName: AnalyticsViewName.AssistantPage,
      viewEntityId: null,
      viewEntityType: null,
    });
  };

  return (
    <div className="w-screen min-h-screen bg-salt flex flex-col items-center px-8 pt-16 pb-24">
      <div className="relative w-full md:w-[60%] p-6 rounded-2xl mb-6 bg-assistant bg-cover bg-center">
        <div className="relative flex flex-col items-center mt-12">
          <div className="relative w-full bg-white rounded-2xl shadow flex flex-col items-center p-4">
            <div className="w-full md:w-64 text-center font-semibold text-charcoal text-base font-inter mt-8">
              Looking for cooking inspiration?
            </div>
            <div className="w-full text-center text-sm font-medium text-truffle mt-2 mb-2 font-inter">
              Take a photo of your ingredients to explore video recipes, or ask
              me anything about food!
            </div>
          </div>
          <FlavrsAILogo
            width={80}
            height={80}
            className="absolute -top-12 left-1/2 transform -translate-x-1/2 z-10"
          />
        </div>
      </div>
      <div className="w-full md:w-[60%] flex flex-col items-center rounded-2xl border-2 border-dashed border-taro">
        <img
          src="/salad.svg"
          alt="Salad Logo"
          className="w-11 h-11 mt-8 mb-8"
        />
        <PrimaryButton
          style={ButtonStyle.Regular}
          onClick={handleCameraButtonClick}
          className="mb-6"
          disabled={isProcessingNewMessage}
          icon={<MdOutlineCameraAlt />}
        >
          Take photo of ingredients
        </PrimaryButton>
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          onChange={handleFileChange}
          className="hidden"
        />
      </div>

      <div className="fixed top-0 w-full flex items-center justify-start bg-salt p-2 mb-2 z-50">
        <FlavrsAIWordmark width={120} height={24} />
        <RWebShare
          data={{
            url: 'https://ai.flavrs.com',
            title: 'Try the 1st AI Cooking Assistant',
          }}
          onClick={handleShare}
        >
          <SecondaryButton
            style={ButtonStyle.Small}
            onClick={() => {}}
            className="ml-auto"
            icon={<IoShareOutline />}
          >
            Share
          </SecondaryButton>
        </RWebShare>
      </div>

      <div className="flex justify-center items-center w-full">
        {loading ? (
          <Lottie
            animationData={loadingSpinnerAnimation}
            loop={true}
            className="w-[150px] h-[300px]"
          />
        ) : error ? (
          <button
            onClick={handleFetch}
            className="mt-4 bg-ash bg-opacity-10 rounded-xl"
          >
            <div className="p-3 flex flex-col items-center">
              <IoReload className="w-12 h-12 p-3 bg-orange-200 text-persimmon rounded-full" />
              <div className="text-regular font-semibold text-charcoal mt-2">
                Tap to retry
              </div>
            </div>
          </button>
        ) : (
          <div className="space-y-4 w-full md:w-[60%] py-8">
            {messages.map(message => (
              <MessageItem
                key={message.id}
                message={message}
                onRecipeClick={handleRecipeClick}
              />
            ))}
            <div ref={messagesEndRef} />
          </div>
        )}
      </div>

      <div className="fixed bottom-0 left-0 w-full flex justify-center pb-2 bg-salt">
        <div className="relative w-full md:w-[75%] px-4">
          {isProcessingNewMessage && (
            <Lottie
              animationData={chatMessageLoadingAnimation}
              loop={true}
              className="w-[75px] h-[150px] fixed bottom-0"
            />
          )}
          <AssistantTextField
            onCameraClick={handleCameraButtonClick}
            onSendClick={handleSendMessage}
            disableSend={isProcessingNewMessage}
          />
        </div>
      </div>
    </div>
  );
}

export default Assistant;
