import * as env from 'env-var';

export const APOLLO_SERVER_URL = env
  .get('REACT_APP_APOLLO_SERVER_URL')
  .required()
  .asUrlString();

export const APOLLO_ENABLE_DEVTOOLS = env
  .get('REACT_APP_APOLLO_ENABLE_DEVTOOLS')
  .default('false')
  .asBoolStrict();

const sentryDsnString = env.get('REACT_APP_SENTRY_DSN').asString();
// SENTRY_DNS can be absent to turn off Sentry.
export const SENTRY_DSN = sentryDsnString
  ? env.get('REACT_APP_SENTRY_DSN').asUrlString()
  : sentryDsnString;

export const SENTRY_ENVIRONMENT = env
  .get('REACT_APP_SENTRY_ENVIRONMENT')
  .required()
  .asString();

export const REACT_APP_AVO_ENVIRONMENT = env
  .get('REACT_APP_AVO_ENVIRONMENT')
  .required()
  .asString();
export const REACT_APP_RUDDERSTACK_WRITE_KEY = env
  .get('REACT_APP_RUDDERSTACK_WRITE_KEY')
  .required()
  .asString();
export const REACT_APP_RUDDERSTACK_DATAPLANE_URL = env
  .get('REACT_APP_RUDDERSTACK_DATAPLANE_URL')
  .required()
  .asString();
