import React, {useEffect} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import Future404 from '../features/404/Future404';
import Assistant from '../features/assistant/Assistant';
import ImageUpload from '../features/imageUpload/ImageUpload';
import SearchResults from '../features/searchResults/SearchResults';
import Recipe from '../features/recipes/Recipe';

import {
  MAIN_ROUTE,
  ASSISTANT_ROUTE,
  IMAGE_UPLOAD_ROUTE,
  SEARCH_RESULTS_ROUTE,
  RECIPE_ROUTE,
} from './routes';

import {MeDocument} from '../services/graphql/apolloTypes';
import {useQuery} from '@apollo/client';
import Avo from '../analytics/Avo';

function Router(): JSX.Element {
  const {loading, data} = useQuery(MeDocument, {
    onError: error => {
      console.error('GraphQL Error:', error);
    },
  });

  useEffect(() => {
    if (!loading && data && data.webMe?.id) {
      Avo.webUserIdentified({userId_: data.webMe.id});
    }
  }, [loading, data]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={MAIN_ROUTE} element={<Assistant />} />
        <Route path={ASSISTANT_ROUTE} element={<Assistant />} />
        <Route path={IMAGE_UPLOAD_ROUTE} element={<ImageUpload />} />
        <Route path={SEARCH_RESULTS_ROUTE} element={<SearchResults />} />
        <Route path={`${RECIPE_ROUTE}/:recipeId`} element={<Recipe />} />
        <Route path="*" element={<Future404 />} />
      </Routes>
    </BrowserRouter>
  );
}
export default Router;
