import {ButtonStyle} from '..';

export class ButtonStyleUtils {
  static getTextSize(style: ButtonStyle): string {
    switch (style) {
      case ButtonStyle.Small:
      case ButtonStyle.SmallOutlined:
        return 'text-sm';
      case ButtonStyle.Regular:
      case ButtonStyle.RegularOutlined:
        return 'text-base';
      case ButtonStyle.Large:
      case ButtonStyle.LargeOutlined:
        return 'text-xl';
      default:
        return 'text-base';
    }
  }

  static getVerticalPadding(style: ButtonStyle): string {
    switch (style) {
      case ButtonStyle.Small:
      case ButtonStyle.SmallOutlined:
        return 'py-2';
      case ButtonStyle.Regular:
      case ButtonStyle.RegularOutlined:
        return 'py-3';
      case ButtonStyle.Large:
      case ButtonStyle.LargeOutlined:
        return 'py-4';
      default:
        return 'py-3';
    }
  }

  static getHorizontalPadding(style: ButtonStyle): string {
    switch (style) {
      case ButtonStyle.Small:
      case ButtonStyle.SmallOutlined:
        return 'px-4';
      case ButtonStyle.Regular:
      case ButtonStyle.RegularOutlined:
        return 'px-6';
      case ButtonStyle.Large:
      case ButtonStyle.LargeOutlined:
        return 'px-8';
      default:
        return 'px-6';
    }
  }

  static getBorderStyle(style: ButtonStyle, dark: boolean): string {
    switch (style) {
      case ButtonStyle.Small:
      case ButtonStyle.Regular:
      case ButtonStyle.Large:
        return '';
      case ButtonStyle.SmallOutlined:
      case ButtonStyle.RegularOutlined:
      case ButtonStyle.LargeOutlined:
        return dark ? 'border border-[sugar]' : 'border border-[charcoal]';
      default:
        return '';
    }
  }

  static getIconSize(style: ButtonStyle): {width: string; height: string} {
    switch (style) {
      case ButtonStyle.Small:
      case ButtonStyle.SmallOutlined:
        return {width: '16px', height: '16px'};
      case ButtonStyle.Regular:
      case ButtonStyle.RegularOutlined:
        return {width: '20px', height: '20px'};
      case ButtonStyle.Large:
      case ButtonStyle.LargeOutlined:
        return {width: '24px', height: '24px'};
      default:
        return {width: '20px', height: '20px'};
    }
  }
}

export default ButtonStyleUtils;
