import React from 'react';
import {WhiteFlavrsWordmark} from '.';

type FlavrsFooterProps = {
  className?: string;
};

function FlavrsFooter({className = ''}: FlavrsFooterProps): JSX.Element {
  return (
    <div className={`bg-black text-center py-8 ${className}`}>
      <WhiteFlavrsWordmark
        width={84}
        height={22}
        className="mx-auto mb-8"
      ></WhiteFlavrsWordmark>
      <p className="text-white mb-4">Thousands more recipes in the app</p>
      <a href="https://apps.apple.com/us/app/flavrs-watch-shop-eat/id1584828914">
        <img
          src="/app-store.svg"
          alt="App Store Badge"
          className="inline-block"
        />
      </a>
      <a
        href="https://www.flavrs.com/privacy-policy"
        className="text-white text-sm mt-20 mb-2 block"
      >
        Privacy Policy
      </a>
      <a
        href="https://www.flavrs.com/terms-of-service"
        className="text-white text-sm mb-2 block"
      >
        Terms & Conditions
      </a>
      <p className="text-white text-sm">© flavrs 2024</p>
    </div>
  );
}

export default FlavrsFooter;
