import {useMutation} from '@apollo/client';
import {
  AskAssistantWithPhotosDocument,
  AskAssistantWithIngredientsDocument,
} from '../../services/graphql/apolloTypes';
import Avo from '../../analytics/Avo';

export const useAssistant = () => {
  const [askAssistantWithPhotosMutation, {error: photosError}] = useMutation(
    AskAssistantWithPhotosDocument
  );
  const [askAssistantWithIngredientsMutation, {error: ingredientsError}] =
    useMutation(AskAssistantWithIngredientsDocument);

  const askAssistantWithPhoto = async (
    gcpPath: string,
    file: File,
    viewName: string
  ) => {
    try {
      const response = await askAssistantWithPhotosMutation({
        variables: {
          input: {media: [{gcpPath: gcpPath, mimeType: file.type}]},
        },
      });
      const askWebAssistantWithPhotos =
        response.data?.askWebAssistantWithPhotos;
      if (askWebAssistantWithPhotos) {
        Avo.webSearchByImages({
          viewName: viewName,
          viewEntityId: null,
          viewEntityType: null,
          imagesCount: 1,
          messageId: askWebAssistantWithPhotos.id,
        });
        if (askWebAssistantWithPhotos.ingredients) {
          localStorage.setItem(
            'searchIngredients',
            JSON.stringify(askWebAssistantWithPhotos.ingredients)
          );
        }
        if (askWebAssistantWithPhotos.recipes) {
          localStorage.setItem(
            'searchRecipes',
            JSON.stringify(askWebAssistantWithPhotos.recipes)
          );
        }
        return askWebAssistantWithPhotos;
      } else {
        throw new Error('Failed to parse assistant response');
      }
    } catch (error) {
      console.error('Error:', error);
      throw new Error('Failed to communicate with assistant');
    }
  };

  const askAssistantWithIngredients = async (
    ingredients: string[],
    viewName: string
  ) => {
    try {
      const response = await askAssistantWithIngredientsMutation({
        variables: {
          input: {ingredients: ingredients},
        },
      });
      const askWebAssistantWithIngredients =
        response.data?.askWebAssistantWithIngredients;
      if (askWebAssistantWithIngredients) {
        Avo.webSearchByIngredients({
          viewName: viewName,
          viewEntityId: null,
          viewEntityType: null,
          ingredientsCount: ingredients.length,
          messageId: askWebAssistantWithIngredients.id,
        });
        localStorage.setItem('searchIngredients', JSON.stringify(ingredients));
        if (askWebAssistantWithIngredients.recipes) {
          localStorage.setItem(
            'searchRecipes',
            JSON.stringify(askWebAssistantWithIngredients.recipes)
          );
        }
        return askWebAssistantWithIngredients;
      } else {
        throw new Error('Failed to parse assistant response');
      }
    } catch (error) {
      console.error('Error:', error);
      throw new Error('Failed to communicate with assistant');
    }
  };

  return {
    askAssistantWithPhoto,
    askAssistantWithIngredients,
    photosError,
    ingredientsError,
  };
};
