import React from 'react';

type WhiteFlavrsWordmarkProps = {
  width: number;
  height: number;
  className?: string;
};

function WhiteFlavrsWordmark({
  width,
  height,
  className = '',
}: WhiteFlavrsWordmarkProps): JSX.Element {
  return (
    <img
      src="/flavrs.svg"
      alt="Flavrs Logo"
      className={`${className}`}
      style={{width: width, height: height}}
    />
  );
}

export default WhiteFlavrsWordmark;
