import React, {useState} from 'react';
import {IoClose} from 'react-icons/io5';
import {
  RecipeMetadataFragment,
  IngredientEntryFragmentFragment,
} from '../../services/graphql/apolloTypes';
import {AvatarImage} from '../../components';

enum SelectedTab {
  Ingredients,
  Steps,
}
type RecipeDetailsCardProps = {
  recipe: RecipeMetadataFragment;
  onClose: () => void;
};

function RecipeDetailsCard({
  recipe,
  onClose,
}: RecipeDetailsCardProps): JSX.Element {
  const [selectedTab, setSelectedTab] = useState<SelectedTab>(
    SelectedTab.Ingredients
  );

  const formatTime = (timeMins: number): string => {
    if (timeMins < 60) {
      return `${timeMins} min`;
    } else {
      const hours = Math.floor(timeMins / 60);
      const minutes = timeMins % 60;
      return `${hours} h${minutes > 0 ? `, ${minutes} min` : ''}`;
    }
  };

  const renderTags = () => {
    return recipe.tags.map(tag => (
      <div
        key={tag.id}
        className="flex items-center justify-center gap-2 rounded-sm py-[1px] px-2 bg-cream text-[11px] text-charcoal"
      >
        {tag.displayName}
      </div>
    ));
  };

  const renderTimes = () => {
    const cookTime = recipe.cookTimeMins;
    const prepTime = recipe.prepTimeMins;
    const totalTime = recipe.totalTimeMins;
    if (cookTime && prepTime) {
      return (
        <div className="flex gap-2">
          <div className="flex items-center justify-center gap-2 rounded-sm py-[1px] px-2 bg-charcoal text-[11px] text-sugar">
            Prep {formatTime(prepTime)}
          </div>
          <div className="flex items-center justify-center gap-2 rounded-sm py-[1px] px-2 bg-charcoal text-[11px] text-sugar">
            Cook {formatTime(cookTime)}
          </div>
        </div>
      );
    } else if (totalTime) {
      return (
        <div className="flex items-center justify-center gap-2 rounded-sm py-[1px] px-2 bg-charcoal text-[11px] text-sugar">
          Total {formatTime(totalTime)}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const getIngredientTitle = (entry: IngredientEntryFragmentFragment) => {
    if (entry.ingredient) {
      return entry.ingredient.displayName;
    } else if (entry.rawIngredientLine && entry.rawIngredientLine.length > 1) {
      return (
        entry.rawIngredientLine[0].toUpperCase() +
        entry.rawIngredientLine.slice(1)
      );
    } else {
      return '';
    }
  };

  const renderIngredients = () => {
    return recipe.ingredientSections.map(ingredientSection => (
      <div key={ingredientSection.name} className="my-2">
        <div className="font-semibold text-lg">{ingredientSection.name}</div>
        {ingredientSection.ingredients.map(ingredient => (
          <div key={ingredient.id} className="flex flex-row my-1">
            <div className="w-[30%] md:w-[20%] text-sm flex flex-row gap-1">
              <div className="font-semibold">{ingredient.quantityStr}</div>
              <div>{ingredient.unit}</div>
            </div>
            <div className="w-[90%] md:w-[80%] text-sm">
              <span className="font-semibold">
                {getIngredientTitle(ingredient)}
              </span>
              {ingredient.preparation ? ', ' + ingredient.preparation : ''}
            </div>
          </div>
        ))}
      </div>
    ));
  };

  const renderSteps = () => {
    return recipe.instructionSections.map(instructionSection => (
      <div key={instructionSection.name} className="my-2">
        <div className="font-semibold text-lg">{instructionSection.name}</div>
        {instructionSection.instructions.map((instruction, index) => (
          <div key={instruction} className="flex flex-row my-1">
            <div className="flex items-center justify-center bg-gray-600 text-white rounded-full h-4 w-4 text-[9px]">
              {index + 1}
            </div>
            <div className="w-full ml-2 text-sm text-shell">{instruction}</div>
          </div>
        ))}
      </div>
    ));
  };

  const renderContent = () => {
    switch (selectedTab) {
      case SelectedTab.Ingredients:
        return renderIngredients();
      case SelectedTab.Steps:
        return renderSteps();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="relative bg-white p-6 rounded-2xl shadow-lg w-[90%] md:w-[35%] md:max-w-164 h-5/6 flex flex-col -mt-12">
        <div className="flex items-center">
          <AvatarImage
            url={recipe.author.avatarUrl}
            placeholder={recipe.author.username}
            placeholderTextClass="text-xs"
            className="w-6 h-6"
          />
          <div className="ml-2 text-xs text-charcoal">
            {recipe.author.username}
          </div>
        </div>

        <div className="text-lg font-semibold text-charcoal mt-1">
          {recipe.title}
        </div>

        <div className="w-full flex flex-wrap gap-2 mt-1">
          {renderTimes()}
          {renderTags()}
        </div>

        <div className="w-full flex flex-row mt-4">
          <button
            className={`w-1/2 text-sm pb-2 ${
              selectedTab === SelectedTab.Ingredients
                ? 'border-b-2 border-charcoal font-semibold'
                : 'text-shell'
            }`}
            onClick={() => {
              setSelectedTab(SelectedTab.Ingredients);
            }}
          >
            Ingredients
          </button>
          <button
            className={`w-1/2 text-sm pb-2 ${
              selectedTab === SelectedTab.Steps
                ? 'border-b-2 border-charcoal font-semibold'
                : 'text-shell'
            }`}
            onClick={() => {
              setSelectedTab(SelectedTab.Steps);
            }}
          >
            Steps
          </button>
        </div>
        <div className="flex-grow overflow-y-auto mt-2 min-h-0 w-full">
          {renderContent()}
        </div>
      </div>

      <button
        className="absolute bottom-4 md:bottom-8 bg-white text-charcoal rounded-full px-2 py-2"
        onClick={onClose}
      >
        <IoClose className="w-10 h-10" />
      </button>
    </div>
  );
}

export default RecipeDetailsCard;
