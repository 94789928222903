import React from 'react';
import * as Sentry from '@sentry/react';
import {
  SENTRY_ENVIRONMENT,
  SENTRY_DSN,
  REACT_APP_AVO_ENVIRONMENT,
} from './app/config';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import Avo from './analytics/Avo';
import AvoRudderStackDestination from './analytics/AvoRudderstackDestination';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  integrations: [Sentry.browserTracingIntegration, Sentry.replayIntegration],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

Avo.initAvo(
  {
    env: REACT_APP_AVO_ENVIRONMENT === 'dev' ? Avo.AvoEnv.Dev : Avo.AvoEnv.Prod,
    webDebugger: false,
  },
  {},
  new AvoRudderStackDestination()
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
